export const CLIENT_ID = '88152814-0071-11e6-a247-000ec6c2375';
export const APP_PREFIX = 'sketch-backoffice';
export const ADMIN_ROLE = 0;
export const CADT_ADMIN_LOGO = '/icons/admin-login.png';

export const COLLECTION_URL = '/collection';
export const CUSTOMERS_URL = '/customers';

/* icons */
export const FILE_ICON = '/icons/file.svg';
export const ROTATE_ICON = '/icons/rotate.svg';
export const YELLOW_STAR_ICON = '/icons/star_icon.svg';

/* sketches filters */

export const SEE_ALL = 'Seeall';

export const LIST_PROGRESS = 0;
export const LIST_ACCEPTED = 1;
export const LIST_INVOICED = 2;
export const LIST_PAID = 3;
export const LIST_CLOSED = 4;
export const LIST_DELETED = 5;

export const LIST_ALL = {
    name: 'TR_ALL_SALES',
    icon: 'CreditCardIcon',
    dbfilter: 'all_sales',
    action: ''
};

export const SALES_STEPS = [
    [LIST_PROGRESS, LIST_ACCEPTED],
    [LIST_ACCEPTED, LIST_INVOICED, LIST_PAID, LIST_CLOSED]
];

export const IN_PROGRESS_SALES = 'started_sales';
export const ACCEPTED_SALES = 'accepted_sales';
export const INVOICED_SALES = 'invoiced_sales';
export const PAID_SALES = 'paid_sales';
export const COMPLETED_SALES = 'closed_sales';
export const REMOVED_SALES = 'removed_sales';

export const SALES_CATEGORIES = [
    {
        name: 'TR_IN_PROGRESS',
        icon: 'WarningIcon',
        dbfilter: IN_PROGRESS_SALES,
        action: ''
    },
    {
        name: 'TR_ACCEPTED',
        icon: 'ShoppingCartIcon',
        dbfilter: ACCEPTED_SALES,
        action: 'sale_to_accepted'
    },
    {
        name: 'TR_INVOICED',
        icon: 'InsertDriveFileIcon',
        dbfilter: INVOICED_SALES,
        action: 'sale_to_invoiced'
    },
    {
        name: 'TR_PAID',
        icon: 'AttachMoneyIcon',
        dbfilter: PAID_SALES,
        action: 'sale_to_paid'
    },
    {
        name: 'TR_COMPLETED',
        icon: 'CheckBoxIcon',
        dbfilter: COMPLETED_SALES,
        action: 'sale_to_closed'
    },
    {
        name: 'TR_DELETED',
        icon: 'DeleteIcon',
        dbfilter: REMOVED_SALES,
        action: ''
    }
];

//COLORS
export const RED_COLOR = '#E21F04';
export const GREY_COLOR = '#685f5fb3';
export const LIGHTER_GREY_COLOR = '#D7D7D7';
export const YELLOW_COLOR = '#fed700';

//DOCUMENT TYPES
export const DOCUMENT_TYPE_NOTE = 'NOTE';
export const DOCUMENT_TYPE_INVOICE = 'INVOICE';
export const DOCUMENT_TYPES = [
    {
        name: DOCUMENT_TYPE_NOTE,
        display_name: 'TR_NOTE',
        hasAttachment: false,
        hasComment: true
    },
    {
        name: DOCUMENT_TYPE_INVOICE,
        display_name: 'TR_INVOICE',
        hasAttachment: true,
        hasComment: false
    }
];

export const SALES_HEADERS = [
    'TR_IMAGE',
    'TR_PRICE',
    'TR_REFERENCE',
    'TR_TECHNICAL_DRAFT',
    'TR_COMMENTS',
    ''
];

/* modal references */
export const MODAL_COLLECTION_REMOVE = 'modal-remove-collection';
export const MODAL_CUSTOMERS_FORM = 'modal-customers-form';
export const MODAL_CUSTOMERS_REMOVE = 'modal-customers-remove';
export const MODAL_CUSTOMERS_BLOCK_UNBLOCK = 'modal-customers-block-unblock';
export const MODAL_SKETCH_FORM = 'modal-sketch-form';
export const MODAL_SKETCH_REMOVE = 'modal-sketch-remove';
export const MODAL_TRENDS_FORM = 'modal-trends-form';
export const MODAL_TRENDS_REMOVE = 'modal-trends-remove';

/* CSS dimensions */
export const DRAWER_WIDTH = 240;

/* Image dimensions */
export const RECOMMENDED_BTN_THEME_SIZE = '619 x 128';
export const RECOMMENDED_COVER_THEME_SIZE = '984 x 984';
export const RECOMMENDED_COLLECTION_SIZE = '1920 x 1080';
export const RECOMMENDED_BTN_TREND_SIZE = '570 x 86';
export const RECOMMENDED_COVER_TREND_SIZE = '984 x 984';

/* Regex */
export const VALID_CHAR_REGEX = new RegExp('^[0-9a-zA-Z!@#$%-.() _]+$');
