import React from 'react';
import { CADT_ADMIN_LOGO } from '../constants';
import useMediaQuery from '../hooks/useMediaQuery';

export default function SplashScreen() {
    const xsMatch = useMediaQuery('(max-width: 600px)');
    const mdMatch = useMediaQuery('(max-width: 900px)');
    const imgWidth = xsMatch ? '30%' : mdMatch ? '20%' : '10%';

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <img alt={'cadt-logo'} width={imgWidth} src={CADT_ADMIN_LOGO} />
        </div>
    );
}
