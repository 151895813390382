import { useEffect, useState } from 'react';

export default function useMediaQuery(query) {
    const mediaQuery = window.matchMedia(query);
    const [queryIsMatching, setMediaCondition] = useState(mediaQuery.matches);

    useEffect(() => {
        function listener() {
            setMediaCondition(mediaQuery.matches);
        }

        mediaQuery.addListener(listener);

        return () => {
            mediaQuery.removeListener(listener);
        };
    }, [mediaQuery]);

    return queryIsMatching;
}
